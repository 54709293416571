import * as React from "react";

import { IconProps } from "../../utils/common";

const EmergencyIcon: React.FC<IconProps> = ({
  width = "47",
  height = "46",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.876 29.1753L26.29 33.6583C20.736 30.3942 16.1075 25.766 12.843 20.2123L17.326 16.6263C17.8511 16.2056 18.2223 15.6229 18.3814 14.9691C18.5406 14.3154 18.4789 13.6273 18.206 13.0123L14.119 3.81228C13.8262 3.15221 13.3084 2.61768 12.6579 2.30409C12.0075 1.9905 11.2668 1.91826 10.568 2.10028L2.79 4.11528C2.06428 4.30521 1.43336 4.75461 1.01669 5.37842C0.600023 6.00223 0.426511 6.75715 0.529003 7.50028C1.89477 17.2203 6.38747 26.2316 13.3281 33.1722C20.2687 40.1128 29.28 44.6055 39 45.9713C39.7428 46.0742 40.4976 45.9009 41.1211 45.4844C41.7447 45.0678 42.1937 44.4369 42.383 43.7113L44.4 35.9323C44.5816 35.2335 44.5092 34.493 44.1956 33.8426C43.8821 33.1923 43.3478 32.6744 42.688 32.3813L33.49 28.3003C32.8756 28.0264 32.1877 27.9638 31.5339 28.1221C30.8801 28.2804 30.2971 28.6507 29.876 29.1753Z"
        fill="#D64545"
      />
      <path
        d="M35.5 7.37689e-07C33.6326 7.97474e-05 31.796 0.475557 30.1631 1.38164C28.5303 2.28772 27.155 3.59457 26.1668 5.17907C25.1787 6.76358 24.6101 8.57357 24.5148 10.4385C24.4195 12.3035 24.8005 14.162 25.622 15.839L24.522 20.783C24.4855 20.9473 24.491 21.1182 24.5381 21.2798C24.5852 21.4414 24.6723 21.5884 24.7914 21.7074C24.9104 21.8264 25.0576 21.9134 25.2192 21.9603C25.3809 22.0073 25.5517 22.0127 25.716 21.976L30.66 20.876C32.1468 21.6053 33.7791 21.9894 35.4352 21.9998C37.0912 22.0102 38.7282 21.6465 40.224 20.9359C41.7199 20.2253 43.036 19.1861 44.0741 17.8959C45.1123 16.6056 45.8458 15.0977 46.2198 13.4844C46.5938 11.8711 46.5988 10.1942 46.2343 8.57881C45.8697 6.96337 45.1452 5.45108 44.1146 4.15475C43.0841 2.85843 41.7741 1.81151 40.2825 1.09211C38.7909 0.372715 37.1561 -0.000605712 35.5 7.37689e-07Z"
        fill="#E66A6A"
      />
      <path
        d="M39.5 12H36.5V15C36.5 15.2652 36.3946 15.5196 36.2071 15.7071C36.0196 15.8946 35.7652 16 35.5 16C35.2348 16 34.9804 15.8946 34.7929 15.7071C34.6054 15.5196 34.5 15.2652 34.5 15V12H31.5C31.2348 12 30.9804 11.8946 30.7929 11.7071C30.6054 11.5196 30.5 11.2652 30.5 11C30.5 10.7348 30.6054 10.4804 30.7929 10.2929C30.9804 10.1054 31.2348 10 31.5 10H34.5V7C34.5 6.73478 34.6054 6.48043 34.7929 6.29289C34.9804 6.10536 35.2348 6 35.5 6C35.7652 6 36.0196 6.10536 36.2071 6.29289C36.3946 6.48043 36.5 6.73478 36.5 7V10H39.5C39.7652 10 40.0196 10.1054 40.2071 10.2929C40.3946 10.4804 40.5 10.7348 40.5 11C40.5 11.2652 40.3946 11.5196 40.2071 11.7071C40.0196 11.8946 39.7652 12 39.5 12Z"
        fill="#FFEEEE"
      />
    </svg>
  );
};

export default EmergencyIcon;

import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import {
  Paper,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button } from "gatsby-theme-material-ui";
import * as React from "react";
import { useEffect, useState } from "react";

import roadmapQuery from "../staticQueries/roadmapQuery";
import roadmapTranslationQuery from "../staticQueries/roadmapTranslationQuery";
import { LPSTypes } from "../utils/constants";
import { getLPSType } from "../utils/getLPSType";
import { checkClosed } from "../utils/timeUtils";
import CarriageIcon from "./icons/CarriageIcon";
import EmergencyIcon from "./icons/EmergencyIcon";
import HospitalIcon from "./icons/HospitalIcon";
import PillsIcon from "./icons/PillsIcon";
import ToothIcon from "./icons/ToothIcon";
import RoadmapButton from "./RoadmapButton";

const sizeButtonIcons = {
  width: "36px",
  heigh: "30px",
};

function Roadmap(): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [clickedToCall, setClickedToCall] = useState(false);
  const { quote, emergency, viewAllButton } = roadmapTranslationQuery();
  const LPSData = roadmapQuery();

  const categoryCounts = LPSData.reduce(
    (previousValue, item) => {
      const category = getLPSType(item.type);

      return {
        ...previousValue,
        [category]: previousValue[category] + 1,
      };
    },
    {
      general: 0,
      pediatrician: 0,
      dentist: 0,
      pharmacy: 0,
    }
  );

  const categoryCountsOpen = LPSData.reduce(
    (previousValue, item) => {
      const category = getLPSType(item.type);
      const isOpen = !checkClosed(item.openDaily, item.openWeekend);

      return {
        ...previousValue,
        [category]: isOpen
          ? previousValue[category] + 1
          : previousValue[category],
      };
    },
    {
      general: 0,
      pediatrician: 0,
      dentist: 0,
      pharmacy: 0,
    }
  );

  useEffect(() => {
    if (clickedToCall) {
      document.location.reload();
      setClickedToCall(false);
    }
  }, [clickedToCall]);

  const content = (
    <Stack
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      height="100%"
      spacing={2}
      marginX={3}
    >
      {isTabletOrDesktop && (
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={1}
        >
          <MedicalServicesIcon fontSize="large" color="primary" />
          <Typography variant="h2" fontSize="24px" fontWeight={400}>
            {quote}
          </Typography>
        </Stack>
      )}
      {isMobile && <Toolbar />}
      {isMobile && (
        <Typography variant="h2" fontSize="24px" fontWeight={400}>
          {quote}
        </Typography>
      )}

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <RoadmapButton
          availability={[categoryCountsOpen.general, categoryCounts.general]}
          type="general"
        >
          <HospitalIcon {...sizeButtonIcons} />
        </RoadmapButton>
        <RoadmapButton
          availability={[
            categoryCountsOpen.pediatrician,
            categoryCounts.pediatrician,
          ]}
          type="pediatrician"
        >
          <CarriageIcon {...sizeButtonIcons} />
        </RoadmapButton>
        <RoadmapButton
          availability={[categoryCountsOpen.dentist, categoryCounts.dentist]}
          type="dentist"
        >
          <ToothIcon {...sizeButtonIcons} />
        </RoadmapButton>
        <RoadmapButton
          availability={[categoryCountsOpen.pharmacy, categoryCounts.pharmacy]}
          type="pharmacy"
        >
          <PillsIcon {...sizeButtonIcons} />
        </RoadmapButton>
        <Button
          to={`/?tags=${LPSTypes.join(",")}`}
          variant="outlined"
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {viewAllButton}
        </Button>
      </Stack>
      <Button
        onClick={() => setClickedToCall(true)}
        to="tel:155"
        variant="outlined"
        sx={{
          width: "183px",
          height: "96px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <EmergencyIcon />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            textTransform="none"
          >
            <Typography
              fontSize="50px"
              fontWeight="bold"
              color="primary"
              sx={{
                lineHeight: "50px",
              }}
            >
              155
            </Typography>
            <Typography fontSize="10px" color="primary">
              {emergency}
            </Typography>
          </Stack>
        </Stack>
      </Button>
    </Stack>
  );

  return (
    <>
      {isTabletOrDesktop ? (
        <Paper sx={{ paddingY: 8, height: "95vh" }}>{content}</Paper>
      ) : (
        content
      )}
    </>
  );
}

export default Roadmap;

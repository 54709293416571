import { graphql, useStaticQuery } from "gatsby";

import { RoadmapQuery } from "../graphqlTypes";

const roadmapQuery = (): RoadmapQuery["allLpsCsv"]["nodes"] => {
  const { allLpsCsv } = useStaticQuery<RoadmapQuery>(graphql`
    query Roadmap {
      allLpsCsv {
        nodes {
          type
          openWeekend
          openDaily
        }
      }
    }
  `);

  return allLpsCsv.nodes;
};

export default roadmapQuery;

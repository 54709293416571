import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Stack, Typography } from "@mui/material";
import { Button } from "gatsby-theme-material-ui";
import * as React from "react";

import LPSIconLabelsQuery from "../staticQueries/LPSIconLabelsQuery";
import roadmapButtonTranslationQuery from "../staticQueries/roadmapButtonTranslationQuery";
import { LPS } from "../utils/common";

interface Props {
  type: LPS;
  availability: number[];
  children: React.ReactNode;
}

function RoadmapButton({ children, type, availability }: Props): JSX.Element {
  const { LPSIconLabels } = LPSIconLabelsQuery();
  const openEmergencies = roadmapButtonTranslationQuery();

  return (
    <Button
      to={`/?tags=${type}`}
      variant="outlined"
      sx={{
        width: "327px",
        height: "72px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        width="100%"
        textTransform="none"
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {children}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0}
          >
            <Typography variant="h3" fontSize="16px" fontWeight={400}>
              {LPSIconLabels[type]}
            </Typography>

            <Typography fontSize="12px" fontWeight={400}>
              {`${availability[0]}/${availability[1]} ${openEmergencies}`}
            </Typography>
          </Stack>
        </Stack>
        <ArrowForwardIosIcon color="primary" />
      </Stack>
    </Button>
  );
}

export default RoadmapButton;

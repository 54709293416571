import { graphql, useStaticQuery } from "gatsby";

import { RoadmapButtonTranslationsQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

const roadmapButtonTranslationQuery =
  (): RoadmapButtonTranslationsQuery["allLocalizableJson"]["edges"][number]["node"]["roadmap"]["openEmergencies"] => {
    const { iso } = useLocalization();

    const { allLocalizableJson } =
      useStaticQuery<RoadmapButtonTranslationsQuery>(graphql`
        query RoadmapButtonTranslations {
          allLocalizableJson {
            edges {
              node {
                roadmap {
                  openEmergencies
                }
                language
              }
            }
          }
        }
      `);

    return allLocalizableJson.edges
      .filter((translation) => translation.node.language === iso)
      .map((translation) => translation.node.roadmap.openEmergencies)[0];
  };

export default roadmapButtonTranslationQuery;

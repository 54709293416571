import { graphql, useStaticQuery } from "gatsby";

import { RoadmapTranslationsQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

const roadmapTranslationQuery =
  (): RoadmapTranslationsQuery["allLocalizableJson"]["edges"][number]["node"]["roadmap"] => {
    const { iso } = useLocalization();

    const { allLocalizableJson } =
      useStaticQuery<RoadmapTranslationsQuery>(graphql`
        query RoadmapTranslations {
          allLocalizableJson {
            edges {
              node {
                roadmap {
                  quote
                  viewAllButton
                  emergency
                }
                language
              }
            }
          }
        }
      `);

    return allLocalizableJson.edges
      .filter((translation) => translation.node.language === iso)
      .map((translation) => translation.node.roadmap)[0];
  };

export default roadmapTranslationQuery;
